<template>
  <b-input-group class="input-group-merge cloud-search-input">
    <b-input-group-prepend is-text>
      <feather-icon icon="SearchIcon" />
    </b-input-group-prepend>
    <b-form-input
      :value="value"
      size="lg"
      :placeholder="$t('forms.search')"
      :debounce="300"
      @input="$emit('input', $event)"
    />
  </b-input-group>
</template>

<script>
import { BFormInput, BInputGroup, BInputGroupPrepend } from 'bootstrap-vue'

export default {
  name: 'CloudSearchInput',
  components: {
    BFormInput, BInputGroup, BInputGroupPrepend,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/utils/responsive.scss";

.cloud-search-input {
  margin: 10px 0;
  max-width: 100%;

  @include respond-to('small') {
    max-width: 450px!important;
  }

  @include respond-to('large') {
    margin: 0;
  }

  input {
    padding: 15px 5px 16px 0!important;
    width: 100%;
    line-height: 1.25;
    &::placeholder {
      font-size: 12px!important;
    }
  }
}
</style>
