<template>
  <vue-perfect-scrollbar
    ref="scroll"
    :settings="{wheelPropagation: true}"
    class="scrollable-container cloud-list scroll-area"
    tagname="div"
    @scroll="onScroll"
  >
    <div id="cloud-list">
      <div id="#grid-sizer" />

      <template v-for="(file, fileIdx) in files">
        <cloud-list-item
          :key="file.id"
          :file="file"
          :selected="file.selected"
          @select="selectFile(file, fileIdx)"
          @layout="recalculate"
        />
      </template>
    </div>
    <div
      v-show="loading"
      class="text-center py-1"
    >
      <b-spinner type="grow" />
    </div>
    <div v-if="!loading && !files.length">
      {{ $t('cloud.no-files-found') }}
    </div>

  </vue-perfect-scrollbar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BSpinner } from 'bootstrap-vue'
import Masonry from 'masonry-layout'
import debounce from 'lodash/debounce'
import CloudListItem from './CloudListItem'

export default {
  name: 'CloudList',
  components: {
    CloudListItem,
    VuePerfectScrollbar,
    BSpinner,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  inject: ['cloud'],
  data() {
    return {
      masonry: null,
    }
  },
  computed: {
    files: {
      get() { return this.cloud.files },
      set(val) { this.cloud.files = val },
    },
    selectedFiles: {
      get() { return this.cloud.selected },
      set(val) { this.cloud.selected = val },
    },
  },
  watch: {
    files() {
      this.$nextTick(() => {
        if (this.masonry?.reloadItems) {
          this.masonry.reloadItems()
          this.$nextTick(() => this.recalculate())
        }
      })
    },
  },
  beforeDestroy() {
    if (this.masonry?.destroy) {
      this.masonry.destroy()
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.masonry = new Masonry('#cloud-list', {
        itemSelector: '.cloud-list-item',
        columnWidth: document.getElementById('grid-sizer'),
        percentPosition: true,
      })
    },
    recalculate: debounce(function () {
      this.$nextTick(() => this.masonry.layout())
    }, 300),
    onScroll(event) {
      const target = event?.target ?? { scrollTop: 0, clientHeight: 0, scrollHeight: 1 }
      const { scrollTop, clientHeight, scrollHeight } = target
      if ((scrollTop + clientHeight >= scrollHeight) && !this.loading) {
        this.$emit('fetch-more')
        this.recalculate()
      }
    },
    selectFile(file, fileIdx) {
      if (this.files[fileIdx].selected) {
        const idx = this.selectedFiles.indexOf(file.id)
        if (idx > -1) {
          this.selectedFiles.splice(idx, 1)
        }
        this.files[fileIdx].selected = false
      } else {
        this.selectedFiles.push(file.id)
        this.files[fileIdx].selected = true
      }
      this.$forceUpdate()
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/utils/responsive.scss";

.cloud-list {
  max-height: 55vh;
  min-height: 40vh;
  overflow: auto;
  margin-top: 1.429rem  /* 20/14 */;

  #grid-sizer, .cloud-list-item {
    width: 100%;

    @include respond-to('small') {
      width: 50%;
      width: calc(50% - 0.714rem  /* 10/14 */);
    }

    @include respond-to('medium') {
      width: 30%;
      width: calc(33.333% - 0.714rem  /* 10/14 */);
    }

    @include respond-to('large') {
      width: 30%;
      width: calc(33.333% - 0.714rem  /* 10/14 */);
    }

    @include respond-to('xlarge') {
      width: 25%;
      width: calc(25% - 0.714rem  /* 10/14 */);
    }
  }

  .cloud-list-item {
    overflow: hidden;
    border-radius: 0.357rem  /* 5/14 */;
    border: 1px solid var(--custom-control-border-color);
    margin-bottom: 0.714rem  /* 10/14 */;
    margin-right: 0.714rem  /* 10/14 */;

    .media-container {
      background-color: var(--white);
      text-align: center;
      min-height: 7.143rem  /* 100/14 */;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      img {
        z-index: 2;
      }

      .assigned-label {
        position: absolute;
        top: 0.714rem  /* 10/14 */;
        right: 0.714rem  /* 10/14 */;
        padding: 0.357rem  /* 5/14 */ 0.429rem  /* 6/14 */;
        border-radius: 1.429rem  /* 20/14 */;
        font-size: 0.786rem  /* 11/14 */;
        background-color: rgba(255, 255, 255, 0.85);
        color: var(--secondary);
        font-weight: 500;
        z-index: 5;
        border: 1px solid var(--secondary);
      }
    }

    &.selected {
      border: 1px solid var(--primary);

      .selected-check {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 4;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.5);
      }
    }

    .file-description {
      padding: 0.5rem  /* 7/14 */ 0.643rem  /* 9/14 */;

      .file-name {
        margin-bottom: 0.714rem  /* 10/14 */;
      }
    }
  }

  .mnassah-no-search-result {
    min-height: 45vh!important;
  }
}
</style>
