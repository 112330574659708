<template>
  <div class="cloud-dropzone-input">
    <vue-dropzone
      id="cloud-dropzone"
      ref="dropzone"
      :options="dropzoneOptions"
      :use-custom-slot="true"
      @vdropzone-files-added="handleFilesAdded"
      @vdropzone-error="handleError"
      @vdropzone-complete="handleComplete"
    >
      <div class="dropzone-custom-content">
        <feather-icon
          icon="ImageIcon"
          size="30"
        />
        <div>
          {{ $t('cloud.drag-or-click-image') }}
        </div>
      </div>
    </vue-dropzone>
  </div>
</template>

<script>
import vueDropzone from 'vue2-dropzone'
import { mapGetters } from 'vuex'
import { API_URL } from '@/constants/config'
import { dropzoneMyMenuTemplate } from '@/@bya3/components/inputs/file-inputs/dropzone-templates'
import ErrorHandler from '@/utils/error-handler'

export default {
  name: 'CloudDropzoneInput',
  components: { vueDropzone },
  data() {
    const imageTypes = 'image/*'

    return {
      upgradeModal: false,
      dropzoneOptions: {
        url: `${API_URL}/cloud/add`,
        thumbnailHeight: 100,
        headers: { Authorization: `Bearer ${this.$store.getters['auth/token']}` },
        acceptedFiles: `${imageTypes}`,
        autoProcessQueue: true,
        previewTemplate: dropzoneMyMenuTemplate(false),
        maxFilesize: 100,
        maxFiles: 50,
        addRemoveLinks: false,
        accept: (file, done) => {
          if (this.totalStorage === 0) {
            return done()
          }

          const FileSizeInMB = file.size / 1000000
          if (FileSizeInMB > this.availableStorage) {
            this.$refs.dropzone.removeFile(file)
            this.upgradeModal = true
            return false
          }

          this.availableStorage -= FileSizeInMB
          return done()
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      totalStorage: 'auth/totalStorage',
    }),
    availableStorage: {
      get() { return this.$store.getters['auth/availableStorage'] },
      set(val) { if (this.availableStorage >= 0) { this.$store.commit('auth/SET_STORAGE', { availableStorage: val }) } },
    },
  },
  methods: {
    async handleFilesAdded(filesList) {
      const files = filesList ? [...filesList] : null
      if (!files || !Array.isArray(files)) {
        return
      }
      await new Promise(resolve => setTimeout(resolve, 300))

      const acceptedFiles = files.filter(file => file.accepted)
      if (acceptedFiles.length) {
        this.$emit('files-added', acceptedFiles)
      }

      await new Promise(resolve => setTimeout(resolve, 3000))
      files.forEach(file => this.$refs.dropzone.removeFile(file))
    },
    handleError(file, message) {
      const error = ErrorHandler.getErrorDetails(message)?.title || message
      const newNode = document.createElement('span')
      newNode.innerText = error

      const element = file.previewTemplate?.getElementsByClassName('dz-error-message-custom')?.[0]
      if (element) {
        element.appendChild(newNode)
      } else {
        file.previewTemplate.appendChild(newNode)
      }
    },
    handleComplete(response) {
      if (response.status !== 'success') {
        this.availableStorage += response.size / 1000000
        return
      }
      this.$emit('uploaded', true)
      setTimeout(() => {
        if (this.$refs.dropzone?.removeFile) this.$refs.dropzone.removeFile(response)
      }, 200)
    },
  },
}
</script>

<style lang="scss">
.cloud-dropzone-input {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 7px 10px!important;
  height: 100%;

  .vue-dropzone {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    min-height: 100px!important;
    border: 1px dashed #aaa8b2!important;
    background-color: #f0eff5!important;

    border-radius: 5px!important;

    width: 100%;
    overflow: hidden;
    padding: 10px!important;

    .dz-preview {
      margin: 10px;
      max-width: 200px;

      .dz-filename {
        max-height: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 110px;
      }
    }

    & > .dz-preview .dz-details {
      background-color: rgba(#5e5773, 0.6);
    }

    .dz-message, .dropzone-custom-content {
      width: 100%;

    }
  }

  .dark-layout & {
    .vue-dropzone {
      border: 1px dashed var(--theme-dark-input-border-color) !important;
      background-color: var(--theme-dark-input-bg) !important;
    }

    .img-thumbnail {
      background-color: var(--theme-dark-body-bg);
    }
  }

  .dz-image-preview {
    max-width: 100%;
  }

  .dz-file-preview-mnassah {
    background-color: var(--white);
    margin: 0 7px 10px!important;
    max-height: 90px!important;
    min-height: 10px!important;
    border-radius: 5px;
    padding: 5px 2px;
    position: relative;

    .preview-container {
      display: flex;
      align-items: center;

      img {
        width: 80px;
        height: 80px;
        min-width: 50px;
        min-height: 50px;
        object-fit: cover;
        border-radius: 5px;
      }
    }

    .dz-file-details {
      padding-left: 3px;
      .dz-error-message-custom {
        font-size: 11px;
      }

      .dz-filename {
        font-size: 11px;
      }

      .dz-size {
        font-size: 12px;
      }
    }

    .dz-progress {
      margin-top: 0!important;
      border-radius: 4px;
      height: 8px;
    }

    .remove {
      padding: 2px;
      position: absolute;
      top: -7px;
      right: -7px;
      background-color: var(--danger);
      border-radius: 5px;
    }
  }
}
</style>
