<template>
  <div
    :class="{selected: selected}"
    class="cloud-list-item shadow"
  >
    <div
      :class="{'cursor-pointer': isImage && !file.assigned}"
      class="media-container"
      @click="file.assigned || !isImage ? null : $emit('select')"
    >
      <div
        :id="`assigned-label-${file.id}`"
        class="assigned-label"
      >
        {{ file.assigned ? $t('cloud.used') : $t('cloud.unused') }}
      </div>

      <b-popover
        v-if="file.assigned && (itemsNames.length || categoriesNames.length)"
        :target="`#assigned-label-${file.id}`"
        triggers="hover"
      >
        <template #title>
          {{ $t('cloud.assigned-items-categories') }}
        </template>

        <template v-if="itemsNames.length">
          {{ $tc('store.item', itemsNames.length) }}
          <ul>
            <li
              v-for="(name, idx) in itemsNames"
              :key="idx"
              v-text="name"
            />
          </ul>
        </template>

        <template v-if="categoriesNames.length">
          {{ $tc('store.category', categoriesNames.length) }}
          <ul>
            <li
              v-for="(name, idx) in categoriesNames"
              :key="idx"
              v-text="name"
            />
          </ul>
        </template>

      </b-popover>

      <div
        v-if="selected"
        class="selected-check"
      >
        <feather-icon
          class="text-white"
          icon="CheckCircleIcon"
          size="32"
        />
      </div>
      <template v-if="isImage">
        <img
          v-show="!failed && loaded"
          :src="`${file.url}`"
          alt="image"
          class="img-fluid"
          @error="handleImageFailed"
          @load="handleImageLoaded"
        >
        <image-placeholder-icon
          v-if="failed"
          color="#F0EFF4"
          size="150"
        />
        <b-spinner v-else-if="!failed && !loaded" />
      </template>
      <ok-video-player
        v-else-if="isVideo"
        :poster="file.video_thumbnail"
        :src="file.url"
        compact
        @data-loaded="$emit('layout')"
      />
    </div>
    <div class="file-description">
      <div class="d-flex justify-content-between align-items-center my-1">
        <b-button
          size="sm"
          variant="primary"
          @click="copyToClipaord(file.url)"
        >
          {{ $t('cloud.copy-url') }}
        </b-button>
        <b-button
          size="sm"
          variant="success"
          @click="copyToClipaord(file.id)"
        >
          {{ $t('cloud.copy-id') }}
        </b-button>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <div
          :title="filename"
          class="text-secondary font-weight-semi-bold text-small file-name"
        >
          {{ shortFilename }}
        </div>
        <ok-switch
          v-if="!file.assigned"
          :value="selected"
          checkbox
          @input="$emit('select')"
        />
      </div>
      <div class="d-flex justify-content-between align-items-center flex-wrap">
        <div class="description-text text-small">
          {{ fileSize }}
        </div>
        <div class="description-text text-small">
          {{ $moment(file.created_at).format('DD/MM/YYYY') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton, BPopover, BSpinner } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import ImagePlaceholderIcon from '@/@bya3/icons/ImagePlaceholderIcon'
import OkVideoPlayer from '@/@bya3/components/media/OkVidePlayer'
import OkSwitch from '@/@bya3/components/inputs/OkSwitch'

export default {
  name: 'CloudListItem',
  components: {
    OkSwitch, OkVideoPlayer, ImagePlaceholderIcon, BSpinner, BPopover, BButton,
  },
  props: {
    file: {
      type: Object,
      default: () => ({}),
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loaded: false,
      failed: false,
    }
  },
  computed: {
    ...mapGetters({
      items: 'menu/flatItems',
      categories: 'menu/flatCategories',
    }),
    hasExtension() {
      return this.file.url && (/^.*\.[^\\/]+$/.test(this.file.url))
    },
    isImage() {
      return this.file.type === 'image'
    },
    isVideo() {
      return this.file.type === 'video'
    },
    filename() {
      return this.file.filename || this.file.url.replace(/^.*[\\/]/, '')
    },
    shortFilename() {
      const name = this.filename
      const extension = this.filename.match(/\.[a-zA-z1-9]+$/)
      if (name.length > 20) {
        return `${name.slice(0, -1 * (name.length - 20))}...${extension || ''}`
      }
      return name
    },
    fileSize() {
      return this.file.size ? `${((this.file.size || 0) / 1e6).toFixed(2)} MB` : '-'
    },
    itemsNames() {
      if (!this.file?.items) return []
      return this.items.filter(item => this.file?.items.includes(item.id)).map(item => item.name)
    },
    categoriesNames() {
      if (!this.file?.categories) return []
      return this.categories.filter(category => this.file?.categories.includes(category.id)).map(category => category.name)
    },
  },
  methods: {
    handleImageLoaded() {
      this.$emit('layout')
      this.loaded = true
    },
    handleImageFailed() {
      this.$emit('layout')
      this.loaded = false
      this.failed = true
    },
    async copyToClipaord(str) {
      await navigator.clipboard.writeText(str)
      this.$notifySuccess(this.$t('cloud.copy-success'))
    },
  },
}
</script>

<style lang="scss">

</style>
