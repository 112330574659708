import { mapGetters } from 'vuex'

const IconMixin = {
  props: {
    size: {
      type: [Number, String],
      default: 20,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      isDark: 'appConfig/isDark',
    }),
  },
}

export default IconMixin
