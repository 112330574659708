<template>
  <empty-card :title="$t('cloud.title')">
    <div class="storage-page mt-1">
      <cloud-dropzone-input @uploaded="resetAndFetch" />

      <cloud-list-header
        class="mt-1"
      />
      <cloud-list
        :loading="loading"
        @fetch-more="fetch"
      />

      <div class="storage-status d-flex mt-1">
        <storage-status-footer />
      </div>

    </div>
  </empty-card>
</template>

<script>
import debounce from 'lodash/debounce'
import CloudAPIs from '@/api/cloud-api'
import EmptyCard from '@/@bya3/components/cards/EmptyCard'
import CloudList from './CloudList'
import StorageStatusFooter from './StorageStatusFooter'
import CloudListHeader from './CloudListHeader'
import CloudDropzoneInput from './CloudDropzoneInput'

export default {
  components: {
    EmptyCard,
    StorageStatusFooter,
    CloudListHeader,
    CloudList,
    CloudDropzoneInput,
  },
  provide() {
    const cloud = {}
    Object.defineProperty(cloud, 'files', {
      enumerable: true,
      get: () => this.files,
      set: val => { this.files = val },
    })

    Object.defineProperty(cloud, 'filters', {
      enumerable: true,
      get: () => this.filters,
      set: val => { this.filters = val },
    })

    Object.defineProperty(cloud, 'selected', {
      enumerable: true,
      get: () => this.selected,
      set: val => { this.selected = val },
    })

    Object.defineProperty(cloud, 'query', {
      enumerable: true,
      get: () => this.query,
      set: val => { this.query = val },
    })

    return {
      cloud,
    }
  },
  data() {
    return {
      query: '',
      files: [],
      selected: [],
      filters: {
        assigned: '',
        type: '',
        ids: '',
        general: '',
        media: '',
      },
      page: 1,
      lastPage: 2,
      loading: false,
      addedFiles: [],
      uploadModal: false,
    }
  },
  watch: {
    active() {
      this.resetAndFetch()
    },
    filters: {
      deep: true,
      handler() {
        this.resetAndFetch()
      },
    },
    query() {
      this.resetAndFetch()
    },
  },
  mounted() {
    this.fetch()
  },
  methods: {
    resetAndFetch() {
      this.files = []
      this.page = 1
      this.lastPage = 2
      this.loading = true
      this.fetch()
    },
    fetch: debounce(function () {
      if (this.page >= this.lastPage) {
        return
      }
      this.loading = true

      CloudAPIs.index(this.page, 18, this.filters.media, this.filters.general, { ...this.filters, query: this.query })
        .then(files => {
          this.files = [...this.files, ...files.data]
          this.lastPage = files?.pagination?.last_page
          this.page += 1
        })
        .finally(() => { this.loading = false })
    }, 1000),
    handleFilesUploaded(files) {
      const filtering = this.query || Object.values(this.filters).some(Boolean)

      if (filtering) {
        this.resetAndFetch()
      } else {
        this.files = [...files, ...this.files]
      }
    },
  },
}

</script>

<style lang="scss">
.storage-page {
  .storage-dropzone-input .vue-dropzone {
    height: 120px!important;
    overflow: hidden;
  }

  .storage-status {
    margin-bottom: -23px;

    & > .storage-status-footer {
      min-width: 300px;
    }
  }
}
</style>
