<template>
  <div class="cloud-list-header">
    <div class="d-flex justify-content-between align-items-center flex-wrap">
      <div class="flex-lg-grow-1">
        <cloud-search-input
          v-model="query"
          class="mr-auto mr-lg-1"
        />
      </div>
      <div class="d-flex justify-content-end align-items-center">
        <div
          v-if="selectedFiles.length"
          class="mr-1 text-danger cursor-pointer"
          @click="removeSelectedFiles"
        >
          <feather-icon
            size="18"
            icon="Trash2Icon"
            title="Remove"
          />
          <sub class="text-extra-small">
            ({{ selectedFiles.length }} {{ $t('cloud.selected') }})
          </sub>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloudAPIs from '@/api/cloud-api'
import CloudSearchInput from './CloudSearchInput'

export default {
  name: 'CloudListHeader',
  inject: ['cloud'],
  components: {
    CloudSearchInput,
  },
  computed: {
    query: {
      get() { return this.cloud.query },
      set(val) { this.cloud.query = val },
    },
    canDelete() {
      return !!this.selectedFiles.length
    },
    selectedFiles() {
      return this.cloud.selected
    },
  },
  methods: {
    removeSelectedFiles() {
      this.$okConfirm(this.$t('cloud.remove-selected-files'), {
        showCancel: true,
        preConfirm: () => CloudAPIs.deleteFiles(this.selectedFiles.join(','))
          .then(() => {
            this.cloud.files = this.cloud.files.filter(file => !this.selectedFiles.includes(file.id))
            this.cloud.selectedFiles = []
          })
          .catch(e => this.$notifyError(e)),
      })
    },
    handleFileUploaded(event) {
      const files = event?.target?.files ?? []
      this.$emit('files-added', files)
    },
    openBrowseFile() {
      document.getElementById('upload-media-input').click()
    },
  },
}
</script>

<style lang="scss">
.cloud-list-header {
  .dropdown-menu {
    z-index: 20;
  }
}
</style>
