<template>
  <svg
    :height="size"
    :width="size"
    viewBox="0 0 111 111"
    xmlns="http://www.w3.org/2000/svg"
  >

    <g
      id="756801ed-ce1d-4d63-8357-a4e2b77d6305"
      :fill="color"
      data-v-423bf9ae=""
      stroke="none"
      transform="matrix(2,0,0,2,20,20)"
    >
      <path d="M9.382 8.675h13.943v13.943L32 31.293V0H.707zM22.618 23.325H8.675V9.382L0 .707V32h31.293z" />
    </g>

  </svg>

</template>

<script>
import IconMixin from '@/mixins/icon-mixin'

export default {
  name: 'ImagePlaceholderIcon',
  mixins: [IconMixin],
  props: {
    transparent: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '',
    },
  },
  computed: {
    bgColor() {
      return this.isDark || this.transparent ? 'transparent' : '#ffffff'
    },
    fillColor() {
      return this.color || (this.isDark ? '#404656' : '#efefef')
    },
  },
}
</script>
