<template>
  <div>
    <validation-observer

      ref="form"
    >
      <empty-card
        :title="$t('otp.title')"
      >
        <template #title>
          <div
            class="d-flex align-items-center"
          >
            <b-button
              variant="dark"
              :disabled="submitting"
              @click="submit()"
            >
              <span :class="{'pr-1': submitting}">{{ $t('forms.save') }}</span>
              <b-spinner
                v-if="submitting"
                small
              />
            </b-button>
          </div>
        </template>
        <div
          class="mt-2"
        >
          <error-alert
            v-if="error"
            :error="error"
          />
          <b-row class="align-items-center">
            <b-col
              md="12"
              class="mb-2"
            >
              <b-form-checkbox
                v-model="form.wp_otp_active"
                switch
              >
                {{ $t(`otp.enable`) }}
              </b-form-checkbox>
            </b-col>
            <template v-if="form.wp_otp_active">
              <b-col
                md="6"
                class="mb-1"
              >
                <ok-text-input
                  v-model="form.otp_message"
                  size="md"
                  textarea
                  :tooltip="$t(`otp.message-hint`)"
                  rules="max:62"
                  :label="$t('otp.message-content')"
                  :show-counter="true"
                  is-required
                />
                <p
                  style="margin-top: 5px"
                >
                  {{ form.otp_message.length +'/'+max }}
                </p>
              </b-col>
            </template>
          </b-row>
        </div>
      </empty-card>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton, BSpinner, BRow, BCol, BFormCheckbox,
} from 'bootstrap-vue'
import ErrorHandler from '@/utils/error-handler'
import EmptyCard from '@/@bya3/components/cards/EmptyCard'
import ErrorAlert from '@/@bya3/components/alerts/ErrorAlert'
import { ValidationObserver } from 'vee-validate'
import OkTextInput from '@/@bya3/components/inputs/OkTextInput'
import OTPAPIs from '@/api/store/otp-api'

export default {
  name: 'OTPForm',
  components: {
    OkTextInput,
    ErrorAlert,
    EmptyCard,
    BButton,
    ValidationObserver,
    BRow,
    BFormCheckbox,
    BCol,
    BSpinner,
  },
  data() {
    return {
      submitting: false,
      sending: false,
      form: {
        wp_otp_active: 0,
        otp_message: 'رمز التحقق الخاص بك هو #otp#',
      },
      error: null,
      max: 62,
    }
  },
  async mounted() {
    await this.init()
  },
  methods: {
    async init() {
      await this.$store.dispatch('settings/FETCH_OTP')
      this.form.wp_otp_active = this.$store.getters['settings/otpActive']
      const msg = this.$store.getters['settings/otpMessage']
      if (msg) this.form.otp_message = msg
    },
    async submit() {
      this.error = null
      const valid = await (this.$refs.form.validateWithInfo())
      if (!valid.isValid) {
        this.error = { title: this.$tc('errors.field-invalid', 2), message: ErrorHandler.getValidationErrors(valid.errors) }
        return
      }
      if (!this.form.otp_message.includes('#otp#')) {
        this.error = { title: this.$tc('errors.field-invalid', 2), message: this.$t('otp.otp-hint') }
        return
      }
      const payload = { ...this.form }
      this.submitting = true
      OTPAPIs.process(payload)
        .then(response => {
          this.$notifySuccess(response.message)
          this.$store.commit('settings/SET_OTP', { ...response })
        })
        .catch(error => { this.error = error })
        .finally(() => { this.submitting = false })
    },
  },
}
</script>
